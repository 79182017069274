import { Col, Row, Spinner } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { MemberServiceType, ReferralType } from "@/apps/common/types/appointment-types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import {dateSort} from "@/apps/common/helpers/date";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom"
import FiltersBar from "./filtersBar";

// components
import PageTitle from "@/apps/common/components/PageTitle";


export default function Referrals() {

  //let showTable = false;
  type rowData = {
    id: number
    createdAt: string
    name: string
    email: string
    phoneNumber: string
    serviceType: string
    paymentType: string
    status: string
  }

  const [selectedReferral, setSelectedReferral] = useState<ReferralType | null>(null)
  const [localDomains, setLocalDomains] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({
  });

  const [formFilters, setFormFilters] = useState<any>({
   
  });

  //const [filters, setFilters] = useState<any>();

  const navigate = useNavigate();
  // const scheduleNewSession = () => {
  //     navigate(`/newsession/${memberService?.id}`)

  // }

  const _setFilters = (formData: any) => {

    console.debug('FILTERS - ' + JSON.stringify(formData))

    let filters = {}
    let formFilters = {}
    if (formData.admin_follow_up === true) {

      filters = {
        user: {
          admin_follow_up: true
        }
      }

      formFilters = {
        admin_follow_up: true
      }
    }

    setFilters(filters)
    setFormFilters(formFilters)
  }

  const handleRowClick = (row: any) => {
    //console.debug('ID ' + JSON.stringify(row.values.id))
    const referral = referrals?.find((x) => {
      return (x.id == row.values.id)

    })
    //console.debug ('selectedMemberService -' + JSON.stringify(memberService))
    setSelectedReferral(referral!)
    //setDisplayDetails(true)
    //navigate(`/memberServiceDetails/${referral?.id}`)

  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.id}
        </a>
      )
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      sort: true,      
      sortType:dateSort,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.createdAt}
        </a>
      )
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.name}
        </a>
      )
    },
    {
      Header: "email",
      accessor:
        "email",
      sort: false,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.email}
        </a>
      )
    },
    {
      Header: "phoneNumber",
      accessor:
        "phoneNumber",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.phoneNumber}
        </a>
      )
    },
    {
      Header: "serviceType",
      accessor:
        "serviceType",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.serviceType}
        </a>
      )
    },
    {
      Header: "paymentType",
      accessor:
        "paymentType",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.paymentType}
        </a>
      )
    },
    {
      Header: "status",
      accessor:
        "status",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        < a
          onClick={() => handleRowClick(row)}
        >
          {row.values.status}
        </a>
      )
    }
  ];
  const { user } = useAuthStore();
  const [referrals, setReferrals] = useState<ReferralType[]>()
  //const [displayDetails, setDisplayDetails] = useState<boolean>(false)
  //const handleClose = () => setDisplayDetails(false)
  const [displayLoadingSpinner, setDisplayLoadingSpinner] = useState<boolean>(true);
  const filterEmail = (email: string | undefined) => {

    // If the env is production then exclude the these subdomains for the list

    if (localDomains === true) {
      //console.debug ('LOCAL DOMAIN. NO EMAIL FILTERING - ' + email )      
      return false;
    }

    if (email?.includes("example") || (email?.includes("skylerhealth") && !email?.includes('care+')) || email?.includes("welcome"))
      return true
    else
      return false

  }

  const fetchedReferrals = () => {
    setDisplayLoadingSpinner(false)
  }

  async function fetchAllReferrals(filters: string) {

    //console.debug(filters)
    // const _filters = filters.admin_follow_up ?
    api.referrals.findMany({ filters }).then((res) => {
      console.debug ('Total Referrals - ' + res.length)
      setReferrals(res)
      setRows([])
      res.forEach((x) => {

        //console.debug('serviceProvider - ' + JSON.stringify(x))

        if (filterEmail(x?.email) == false) {

          const row = {
            id: x?.id,
            createdAt: x?.createdAt ? new Date(x?.createdAt)?.toDateString() : 'ERROR',
            name: x?.firstName + ' ' + x?.lastName,
            email: x?.email ? x?.email : "N/A",
            phoneNumber: x?.phoneNumber ? x?.phoneNumber : "N/A",
            serviceType: x?.serviceType ? x?.serviceType.name : "N/A",
            paymentType: x?.paymentType ? x?.paymentType : "N/A",
            status: x?.status ? x?.status : "N/A",            
          }

          //showTable = true;

          setRows(prev => [...prev, row])
        }

      })
      return res
    })
  }

  const { data: _referrals = [] } = useRequest(
    () =>
      fetchAllReferrals(filters),
    {
      refreshDeps: [filters],
      debounceWait: 300,
      onSuccess: fetchedReferrals
    }
  );

  useEffect(() => {
    if (API_BASE_URL.API_URL.includes('localhost') ||
      API_BASE_URL.API_URL.includes('dev') ||
      API_BASE_URL.API_URL.includes('beta')) {
      setLocalDomains(true)
    }
  }, [user, _referrals]);

  const a: rowData[] = [];
  const [rows, setRows] = useState(a);
  const initialState = { hiddenColumns: ['Id'] };

  return (
    <>
      <PageTitle
        title={"Member Referrals"}
      />
      {/* <Row>
        <Col>
          <FiltersBar values={formFilters} onChange={_setFilters} />
        </Col>
      </Row> */}
      {displayLoadingSpinner ?
        (
          <div className="d-flex justify-content-center">
            <Spinner></Spinner>
          </div>
        )
        : (
          <>
            <p><strong>Dates displayed in {Intl.DateTimeFormat().resolvedOptions().timeZone} TimeZone</strong></p>
            <div>
              <Table
                columns={columns}
                data={rows}
                pageSize={20}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </div>
          </>)}
    </>
  );
}


