import { AxiosInstance } from "axios";
import { APPOINTMENT_STATUS } from "../../constants";
import { transformTimeSlots } from "../../helpers";
import { reverseFormatDateOfBirth } from "../../helpers/date";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { AppointmentType, TimeSlotType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

interface TimeSlotData {
  start: Date;
  end: Date;
  tentative: boolean;
}

const DEFAULT_POPULATE = [
  "memberService.clinic",
  "memberService.memberReport",
  "memberService.memberReport.template",
  "memberService.providerReport.document",
  "memberService.providerReport.memberDocument",
  "memberService.providerReport.template",
  "memberService.referral.state",
  "memberService.referral.serviceType",
  "memberService.serviceType",
  "memberService.user.state",
  "memberService.user.telehealth_location.state",
  "memberService.paymentLogs",
  "timeSlot.serviceProvider.user.profilePicture",
  "memberService.providerReport.cpt_codes",
  "memberService.user.telehealth_location"
];

export class AppointmentsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/appointments");
  }

  async getById(id: number) {
    return this.client
      .get(this.buildPath(String(id)), {
        params: {
          populate: DEFAULT_POPULATE
        }
      })
      .then((res) => unstrapiOne<AppointmentType>(res.data, "appointment"));
  }

  async create(data: { timeSlot: string; memberService: number }) {
    return await this.client.post(this.buildPath(), { data }).then((res) => {
      return unstrapiOne<AppointmentType>(res.data, "appointment");
    });
  }

  async updateStatus(id: number, data: { status: string }) {
    return await this.client.put(`api/appointments/${id}`, { data });
  }

  async respond(id: number, status: string) {
    return await this.client
      .post(`api/appointments/${id}/respond`, { status })
      .then((res) => {
        return unstrapiOne<AppointmentType>(res.data, "appointment");
      });
  }

  async reschedule(id: number, data?: unknown) {
    return await this.client
      .post(`api/appointments/${id}/reschedule`, data)
      .then((res) => {
        return unstrapiOne<AppointmentType>(res.data, "appointment");
      });
  }

  async cancel(id: number) {
    return await this.client
      .post(`api/appointments/${id}/cancel`)
      .then((res) => {
        return unstrapiOne<AppointmentType>(res.data, "appointment");
      });
  }

  findMany({
    query,
    filters,
    populate
  }: { query?: string; filters?: any; populate?: string[] } = {}) {
    const queryFilter: any = [];

    query = query?.trim();

    if (query?.length) {
      queryFilter.push(
        ...["firstName", "lastName", "email"].map((field) => ({
          [field]: { $containsi: query }
        }))
      );

      const dateOfBirth = reverseFormatDateOfBirth(query);
      if (dateOfBirth) {
        queryFilter.push({
          dateOfBirth
        });
      }

      const phoneNumber = query.replace(/[^+\d]+/g, "");
      if (phoneNumber.length > 5) {
        queryFilter.push({
          phoneNumber: { $containsi: phoneNumber }
        });
      }
    }

    const { status, ...restFilters } = filters ?? {};

    return this.client
      .get(this.buildPath(), {
        params: {
          filters: {
            memberService: { user: { $or: queryFilter }, ...restFilters },
            status: {
              $notIn: [
                APPOINTMENT_STATUS.CANCELLED,
                APPOINTMENT_STATUS.AWAITING_PAYMENT,
                APPOINTMENT_STATUS.NO_SHOW,
                APPOINTMENT_STATUS.RESCHEDULED,
                APPOINTMENT_STATUS.PAYMENT_FAILED
              ],
              $in: status
            }
          },
          pagination: { pageSize: 100 },
          populate: populate ?? DEFAULT_POPULATE,
          sort: "timeSlot.startDate:DESC"
        }
      })
      .then((res) => {
        return unstrapiMany<AppointmentType>(res.data, "appointment");
      });
  }

  async fetchTimeSlots() {
    return this.client
      .get(`api/service-provider-time-slots`)
      .then((response) => unstrapiMany<TimeSlotType>(response.data, "timeSlot"))
      .then((timeSlots) => {
        return transformTimeSlots(timeSlots, true);
      });
  }

  async fetchMemberAvailableTimeSlots(
    serviceProviderId?: number,
    slotType?: number | undefined
  ) {
    return this.client
      .get(`api/time-slots/available`, {
        params: {
          serviceProviderId,
          slotType
        }
      })
      .then((response) => {
        //console.debug ( 'timeslots - ' + JSON.stringify(response.data))
        return transformTimeSlots(response.data);
      });
  }

  async createTimeSlots(data: TimeSlotData): Promise<unknown> {
    return await this.client.post(`api/service-provider-time-slots/`, data);
  }

  async deleteTimeSlotByRange(
    data: Pick<TimeSlotData, "start" | "end">
  ): Promise<unknown> {
    return await this.client.post(
      `/api/service-provider-time-slots/deleteTimeSlotRange`,
      data
    );
  }

  async startAssistant(appointmentId: number) {
    return await this.client.post(
      `api/appointments/${appointmentId}/start-assistant`
    );
  }

  async startRecording(appointmentId: number) {
    return await this.client.post(
      `api/appointments/${appointmentId}/start-recording`
    );
  }

  async summarize(appointmentId: number) {
    return await this.client.post(
      `api/appointments/${appointmentId}/summarize`
    );
  }
}
