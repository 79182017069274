import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { api } from "@/apps/common/api-client";
import { formatDate } from "@/apps/common/helpers/date";
import config from "@/config";
import { useRequest } from "ahooks";
import { Link } from "react-router-dom";
import LoaderSpinner from "./LoaderSpinner";

export default function ReportPreview({
  report,
  saveDraft,
  loading,
  submitReport
}: any) {
  const [reportLoading, setReportLoading] = useState(false);
  const [confirmSubmissionModal, setConfirmSubmissionModal] = useState(false);

  const { data, runAsync: refreshReport } = useRequest(
    () => api.evaluations.getReportById(report.id),
    {
      manual: true
    }
  );

  const { runAsync: requestReportPDF } = useRequest(
    () => api.common.generateEvaluationReportPDF(report.id),
    {
      manual: true
    }
  );

  const updatedReport = data ?? report;

  const handleGenerateReport = useCallback(async () => {
    const isValid = await saveDraft(true);
    if (!isValid) {
      return;
    }

    setReportLoading(true);
    await requestReportPDF();
    await refreshReport();
    setReportLoading(false);
  }, [refreshReport, requestReportPDF, saveDraft]);

  const confirmSubmission = async () => {
    const isValid = await saveDraft(true);

    if (!isValid) {
      return;
    }

    setConfirmSubmissionModal(true);
  };

  const onSubmit = async () => {
    setConfirmSubmissionModal(false);
    setReportLoading(true);
    await submitReport();
    await refreshReport();
    setReportLoading(false);
  };

  let reportLink = updatedReport?.document?.url;
  if (reportLink && !reportLink.startsWith("http")) {
    reportLink = config.API_URL + reportLink;
  }

  return (
    <>
      {updatedReport?.status !== "complete" ? (
        <Button
          disabled={reportLoading}
          type="submit"
          variant={"primary"}
          className="flex-0"
          onClick={handleGenerateReport}
        >
          {reportLoading ? (
            <>
              <LoaderSpinner />
            </>
          ) : (
            "Generate Preview"
          )}
        </Button>
      ) : null}
      {reportLink && !reportLoading ? (
        <>
          <Link to={reportLink} target="_blank">
            Download Report <br />
            (Last updated: {formatDate(updatedReport?.document.createdAt)})
          </Link>
          {updatedReport?.status !== "complete" ? (
            <>
              <Button
                disabled={loading}
                onClick={confirmSubmission}
                variant={"primary"}
              >
                {loading ? <LoaderSpinner /> : "Submit"}
              </Button>
              <p>
                Please make sure to Submit the generated report once you have
                reviewed the generated draft. The report will not be shared with
                the clinic or the member if you do not Submit. Also, once you
                Submit, the report is uneditable.
              </p>
            </>
          ) : null}
        </>
      ) : null}

      <Modal
        onHide={() => setConfirmSubmissionModal(false)}
        show={confirmSubmissionModal}
        centered
      >
        <Modal.Header closeButton>
          <h4 className="modal-title block">Confirm Submission</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please confirm if you want to submit the report. Once submitted, the
            report can neither be edited nor deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit} className="w-100">
            {"Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
