import { AxiosInstance } from "axios";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { MemberServiceType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";
import { filter } from "lodash-es";

export class MemberServiceApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async findMany(): Promise<MemberServiceType[]> {
    return await this.client
      .get(`api/member-services`, {
        params: {
          populate: [
            "serviceType",
            "memberReport.template",
            "user",
            "appointment.timeSlot",
            "paymentProfile",
            "referral.state",
            "providerReport.memberDocument",
            "providerReport.document",
            "paymentLogs"
          ],
          sort: "createdAt:DESC"
        }
      })
      .then((res) =>
        unstrapiMany<MemberServiceType>(res.data, "memberService")
      );
  }

  async findManyforAdmin({ filters,
    populate
  }: { filters?: any; populate?: string[] } = { filters: null, populate: [] }
  ): Promise<MemberServiceType[]> {


    const { admin_follow_up, ...restFilters } = filters ?? {};

    //console.debug ('admin_follow_up ' + JSON.stringify(admin_follow_up))

    return await this.client
      .get(`api/member-services`, {
        params: {
          populate: [
            "id",
            "serviceType",
            "user",
            "appointment",
            "appointment.timeSlot",
            "paymentProfile",
            "referral.state",
            "paymentLogs",
            "paymentType",
            "status",
            "user.state",
            "invoice",
            "invoice.invoiceReport"
          ],
          filters: filters,
          sort: "createdAt:DESC"
        }
      })
      .then((res) =>{
        //console.debug (JSON.stringify(res.data))
        return unstrapiMany<MemberServiceType>(res.data, "memberService")
      }
      );
  }

  async findMemberService(id: number): Promise<MemberServiceType> {

    return await this.client.get(`api/member-services/${id}`, {
      params: {
        populate: [
          "id",
          "serviceType",
          "user",
          "appointment",
          "appointment.timeSlot",
          "appointment.timeSlot.serviceProvider.user",
          "paymentProfile",
          "referral",
          "referral.state",
          "referral.clinic",
          "referral.serviceProvider.user",
          "paymentLogs.paymentMethod",
          "paymentType",
          "status",
          "serviceProvider",
          "serviceProvider.user",
          "user.insurance_provider",
          "user.telehealth_location",
          "user.telehealth_location.state",
          "user.health_insurance",
          "user.state",
          "user.account",
          "user.spokenLanguage",
          "events",
          "providerReport",
          "providerReport.document",
          "providerReport.cpt_codes",
          //"serviceProvider.user.insurance_provider"

        ],
      }
    }).then(

      (res) => {
        //console.debug ('Raw MemberService Payload ' + JSON.stringify(res.data.data.attributes.paymentLogs))
        return unstrapiOne<MemberServiceType>(res.data, "memberService")

      }

    );


  }

  async update(
    id: number,
    data: { serviceType: number; serviceProviderType: string }
  ): Promise<MemberServiceType> {
    return await this.client.put(`api/member-services/${id}`, { data });
  }
  async updateInsuranceValidation(
    id: number,
    data: { teleHealthLocationValidation: boolean; healthInsuranceValidation: boolean }
  ): Promise<MemberServiceType> {
    return await this.client.put(`api/member-services/${id}`, { data });
  }

  async updatePaymentType(
    id: number,
    data: { paymentType: string }
  ): Promise<MemberServiceType> {
    //console.debug("updatePaymentType - " + JSON.stringify(data));
    return await this.client.put(`api/member-services/${id}`, { data });
  }

  // async updateTeleHealthLocation(
  //   id: number,
  //   data: { telehealth_location : number}
  // ): Promise<MemberServiceType> {
  //   console.debug ('updateTeleHealthLocation - ' + JSON.stringify(data))
  //   return await this.client.put(`api/member-services/${id}`, { data });
  // }

  async updateStatus(
    id: number,
    data: { status: string }
  ): Promise<MemberServiceType> {
    return await this.client.put(`api/member-services/${id}`, { data });
  }

  async startNewSession(data: {
    serviceType?: number;
    timeSlotId?: number | string;
    memberServiceId?: number;
  }): Promise<MemberServiceType> {

    //console.debug ('startNew Session - ' + JSON.stringify(data))
    return await this.client
      .post(`api/member-services/new`, { data })
      .then((res) => res.data);
  }

  async chargeCopay(data: {
    memberServiceId: number;
    amount: number;
  }): Promise<MemberServiceType> {
    return await this.client
      .post(`api/member-services/copay`, { data })
      .then((res) => res.data);
  }

}
